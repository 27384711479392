import { each, filter, isUndefined, map, reduce, remove } from 'lodash-es';
import { planesState } from '~/store/States/pospago';
import { LOCALE } from '~/store/States/global';
import { productList } from '~/store/Catalog/Catalog';

const productHelpers = {
  /**
   * Generate color dictionary
   * @param {obj} props
  */
  colorProduct(props, size) {
    const productList = {};
    remove(props.items, item => !(item.available === true));
    each(props.items, (data) => {
      const attrMap = this.convertAttributes(data.attributes);
      const colorFilter = attrMap['def-color'];
      const sizeFilter = attrMap['def-capacidad'];
      const images = [];
      if (colorFilter) {
        for (let i = 0; i < 8; i += 1) {
          if (data && data.images && data.images.length && data.images[i]) {
            images.push(data.images[i]);
          }
        }

        if (!productList[colorFilter] || sizeFilter === size) {
          productList[colorFilter] = {
            color: colorFilter,
            size: sizeFilter,
            part_number: data.part_number,
            locales: data.locales,
            pricing: data.pricing,
            available: data.available,
            attributes: data.attributes,
            item: data,
            images,
          };
        }
      }
    });
    return productList;
  },

  /**
   * Generate size dictionary
   * @param {obj} props
  */
  sizeProduct(props) {
    const sizeList = {};
    each(props.items, (data) => {
      const sizeFilter = filter(data.attributes, { name: 'def-capacidad' });
      const colorFilter = filter(data.attributes, { name: 'def-color' });
      if (colorFilter.length || sizeFilter.length) {
        if (isUndefined(sizeList[colorFilter[0].value])) sizeList[colorFilter[0].value] = [];
        sizeList[colorFilter[0].value].push({
          size: sizeFilter[0].value,
          part_number: data.part_number,
          locales: data.locales,
          pricing: data.pricing,
          attributes: data.attributes,
          item: data,
          images: data.images,
        });
      }
    });
    return sizeList;
  },

  itemByColorAndSize(props) {
    const item = {};
    each(props.items, (data) => {
      const sizeFilter = filter(data.attributes, { name: 'def-capacidad' });
      const colorFilter = filter(data.attributes, { name: 'def-color' });
      if (colorFilter.length || sizeFilter.length) {
        if (isUndefined(item[colorFilter[0].value])) item[colorFilter[0].value] = {};
        if (isUndefined(item[colorFilter[0].value][sizeFilter[0].value])) {
          item[colorFilter[0].value][sizeFilter[0].value] = {};
        }
        item[colorFilter[0].value][sizeFilter[0].value] = { part_number: data.part_number, available: data.available, buyable: data.buyable, size: sizeFilter[0].value };
      }
    });
    return item;
  },

  tipo(producto) {
    for (let i = 0; i < producto.attributes.length; i += 1) {
      const attr = producto.attributes[i];
      if ((attr.identifier || attr.name) === 'attr-conf-tipoproducto') {
        return attr.value;
      }
    }
    return '';
  },
  tipoPlan(producto) {
    for (let i = 0; i < producto.attributes.length; i += 1) {
      const attr = producto.attributes[i];
      if ((attr.identifier || attr.name) === 'attr-conf-tipoplan') {
        return attr.value;
      }
    }
    return '';
  },

  recurrente(producto) {
    for (let i = 0; i < producto.attributes.length; i += 1) {
      const attr = producto.attributes[i];
      if ((attr.identifier || attr.name) === 'attr-conf-pagorecurrente') {
        return attr.value;
      }
    }
    return '';
  },

  convertAttributes(attributes) {
    return attributes.reduce((obj, attribute) => {
      obj[attribute.identifier] = attribute.value;
      return obj;
    }, {});
  },

  ordenar(producto) {
    producto.items = producto.items.filter(it => it.available).sort((it1, it2) => {
      if (it1.position < it2.position) {
        return -1;
      } else if (it1.position > it2.position) {
        return 1;
      }
      return it1.pricing[process.env.NEXT_PUBLIC_CURRENCY].offer_price - it2.pricing[process.env.NEXT_PUBLIC_CURRENCY].offer_price;
    });
  },

  arrayToObjectProductInfo(exp, props, color, size) {
    let attrs;
    let attrsTech;
    if (props && props.length) {
      map(props[0].items, (data) => {
        const itemColor = filter(data.attributes, { identifier: 'def-color' });
        const itemSize = filter(data.attributes, { identifier: 'def-capacidad' });
        if ((!itemColor[0] || !itemSize[0]) || (itemColor[0].value === color && itemSize[0].value === size)) {
          attrsTech = reduce(data.attributes, (a, b) => {
            if ((b.name).match(exp)) {
              a[b.name] = b.value;
            }
            return a;
          }, {});
          attrs = attrsTech;
        }
      });
    }

    return attrs;
  },

  arrayToObjectPlanInfo(exp, props) {
    let attrs;
    let attrsTech;
    if (props.data[0]) {
      map(props.data[0].items, (data) => {
        if (data) {
          attrsTech = reduce(data.attributes, (a, b) => {
            if ((b.name).match(exp)) {
              a[b.name] = b.value;
            }
            return a;
          }, {});
          attrs = attrsTech;
        }
      });
    }
    return attrs;
  },

  arrayToObjectProducGenerictInfo(exp, props, color, size, screen) {
    const attrs = [];
    if (props) {
      map(props.items, (data) => {
        const itemColor = filter(data.attributes, { identifier: 'def-color' });
        const itemSize = filter(data.attributes, { identifier: 'def-capacidad' });
        const itemScreen = filter(data.attributes, { identifier: 'def-pantalla' });
        if ((!itemColor[0] || !itemSize[0]) || (itemColor[0].value === color && itemSize[0]?.value === size && (itemScreen[0]?.value || '') === screen)) {
          map(data.attributes, (b) => {
            const a = {
              identifier: null,
              value: null,
            };
            if ((b.identifier).match(exp)) {
              a.identifier = b.identifier;
              a.value = b.value;
              attrs.push(a);
            }
          });
        }
      });
    }

    return attrs;
  },
  arrayToObjectProducPospagoInfo(exp, props, color, size) {
    const attrs = [];
    if (props.producto) {
      map(props.producto.items, (data) => {
        const itemColor = filter(data.attributes, { identifier: 'def-color' });
        const itemSize = filter(data.attributes, { identifier: 'def-capacidad' });
        //const itemScreen = filter(data.attributes, { identifier: 'def-pantalla' });
        if ((!itemColor[0] || !itemSize[0]) || (itemColor[0].value === color && itemSize[0].value === size)) {
          map(data.attributes, (b) => {
            const a = {
              identifier: null,
              value: null,
            };
            if ((b.identifier).match(exp)) {
              a.identifier = b.identifier;
              a.value = b.value;
              attrs.push(a);
            }
          });
        }
      });
    }

    return attrs;
  },
  arrayToObjectProducGenerictBasicInfo(exp, props, color, size, screen) {
    const attrs = [];
    if (props) {
      map(props.items, (data) => {
        const itemColor = filter(data.attributes, { identifier: 'def-color' });
        const itemSize = filter(data.attributes, { identifier: 'def-capacidad' });
        const itemScreen = filter(data.attributes, { identifier: 'def-pantalla' });
        if ((!itemColor[0] || !itemSize[0]) || (itemColor[0].value === color && itemSize[0].value === size && (itemScreen[0]?.value || '') === screen)) {
          map(data.attributes, (b) => {
            const a = {
              identifier: null,
              value: null,
            };
            if ((b.identifier).match(exp)) {
              a.identifier = b.identifier;
              a.value = b.value;
              attrs.push(a);
            }
          });
        }
      });
    }

    return attrs;
  },
  arrayToObjectPlan(exp, plan) {
    const attrs = [];
    if (plan) {
      map(plan.attr, (b) => {
        const a = {
          name: null,
          value: null,
        };
        if ((b.name).match(exp)) {
          a.name = b.name;
          a.value = b.value;
          attrs.push(a);
        }
      });
    }

    return attrs;
  },
};

export const pospagoName = async (products = []) => {
  const list = products.filter(prod => prod.type === '6');
  if (list.length) {
    const ref = list[0].locales[LOCALE].categories.find(cat => cat.level === 2).slug;
    const listaPospago = await productList(ref, 0);
    const planList = new Set(listaPospago.data.filter(p => p.type === '2').map(p => p.items[0].part_number));
    const planes = new Set();
    list.forEach((prod) => {
      productHelpers.ordenar(prod);
      const item = prod.items[0];
      if (!item) return;
      const attrMap = productHelpers.convertAttributes(item.attributes);
      let plan = attrMap['attr-conf-default-plan']?.replace(/\./g, '_');
      const plazo = attrMap['attr-conf-default-plazo'];
      if (!(plan && planList.has(plan)) || !item.pricing.pospago?.[plan]) {
        plan = Object.keys(item.pricing.pospago).filter(p => planList.has(p))
          .sort((p1, p2) => {
            let pl1 = plazo;
            if (!pl1 || !item.pricing.pospago[p1][pl1]) {
              pl1 = Object.keys(item.pricing.pospago[p1])
                .sort((a, b) => b - a)[0];
            }
            let pl2 = plazo;
            if (!pl2 || !item.pricing.pospago[p2][pl2]) {
              pl2 = Object.keys(item.pricing.pospago[p2])
                .sort((a, b) => b - a)[0];
            }
            return item.pricing.pospago[p1][pl1].offer_price - item.pricing.pospago[p2][pl2].offer_price;
          })[0];
      }
      const prices = item.pricing.pospago?.[plan];

      if (prices) {
        let first = plazo;
        if (!first || !prices[first]) {
          first = Object.keys(prices).sort((a, b) => b - a)[0];
        }
        planes.add(plan);
        item.plan = plan;
        item.pricing[process.env.NEXT_PUBLIC_CURRENCY].offer_price = prices[first].offer_price * 100;
        item.pricing[process.env.NEXT_PUBLIC_CURRENCY].list_price = prices[first].list_price * 100;
      }
    });
    if (planes.size) {
      const filtered = Array.from(planes).filter(plan => !planesState.value.some(pl => pl === plan));
      planesState.merge(filtered);
    }
  }
  return products;
};

export const calculateTheMinPriceOffer = (items) => {
  const itemsAvailable = items.filter(item => item.available === true);
  if (itemsAvailable) {
    let minOfertPrice = [];

    let productWithPrice = [];
    // let minPrice = Infinity ;
    let minPrice = itemsAvailable[0].pricing[process.env.NEXT_PUBLIC_CURRENCY].offer_price;

    if (itemsAvailable.length > 1) {
      itemsAvailable.map((product) => {
        if (product.pricing[process.env.NEXT_PUBLIC_CURRENCY].offer_price === minPrice) {
          const tempProduct = { ...product.pricing[process.env.NEXT_PUBLIC_CURRENCY], part_number: product.part_number };
          productWithPrice.push(tempProduct);
        }

        if (minPrice > product.pricing[process.env.NEXT_PUBLIC_CURRENCY].offer_price) {
          productWithPrice = [];

          minPrice = product.pricing[process.env.NEXT_PUBLIC_CURRENCY].offer_price;
          const tempProduct = { ...product.pricing[process.env.NEXT_PUBLIC_CURRENCY], part_number: product.part_number };

          productWithPrice.push(tempProduct);
        }
      });

      if (productWithPrice.length === 1) {
        // minOfertPrice = itemsAvailable[0].pricing;
        minOfertPrice = { ...productWithPrice[0], hasPromoOffer: true };
        // console.log("si tengo offerta y esta es: ",minOfertPrice)
        return minOfertPrice;
      }

      if (productWithPrice.length === itemsAvailable.length) {
        minOfertPrice = { ...itemsAvailable[0].pricing[process.env.NEXT_PUBLIC_CURRENCY], hasPromoOffer: false, part_number: itemsAvailable[0].part_number };
        // console.log("era repedito el precio",minOfertPrice)
        return minOfertPrice;
      }

      let finalPriceWithOffer = { [process.env.NEXT_PUBLIC_CURRENCY]: productWithPrice[0] };
      const minPriceList = productWithPrice[0].list_price;
      // debugger

      productWithPrice.map((price) => {
        if (price.list_price < minPriceList) {
          finalPriceWithOffer = price;
          finalPriceWithOffer = { [process.env.NEXT_PUBLIC_CURRENCY]: price };
        }
      });

      //  console.log("precio con la oferta mas grande y precio list mas bajo",finalPriceWithOffer)
      return { ...finalPriceWithOffer[process.env.NEXT_PUBLIC_CURRENCY], hasPromoOffer: true };
    }
    minOfertPrice = { ...itemsAvailable[0].pricing[process.env.NEXT_PUBLIC_CURRENCY], hasPromoOffer: false, part_number: itemsAvailable[0].part_number };
    // console.log("tengo solo un",minOfertPrice)
    return minOfertPrice;
  }
};

export const getProductsColors = (items) => {
  const allColorResult = [];
  // let itemsAvailable = items.filter(item => item.available === true);

  // console.log(itemsAvailable)
  // if(itemsAvailable){
  items.map((product) => {
    const attrMap = productHelpers.convertAttributes(product.attributes);
    const colorFilter = attrMap['def-color'];
    if (!allColorResult.includes(colorFilter) && colorFilter) {
      allColorResult.push(colorFilter);
    }
  });
  // }

  return allColorResult;
};

export const getProductsSize = (items) => {
  const allSizeResult = [];
  // let itemsAvailable = items.filter(item => item.available === true);
  // if(itemsAvailable){
  items.map((product) => {
    const attrMap = productHelpers.convertAttributes(product.attributes);
    const sizeFilter = attrMap['def-capacidad'];
    if (!allSizeResult.includes(sizeFilter) && sizeFilter) {
      allSizeResult.push(sizeFilter);
    }
  });
  // }

  const sizeWithoutGB = [];

  allSizeResult.map(size => sizeWithoutGB.push({ size, id: parseInt(size.replace(/[^0-9.]/g, '')) }));
  sizeWithoutGB.sort((a, b) => a.id - b.id);

  return sizeWithoutGB;
};

export const getProductsSizeScreen = (items) => {
  const allSizeScreenResult = [];
  // let itemsAvailable = items.filter(item => item.available === true);
  // if(itemsAvailable){
  items.map((product) => {
    const attrMap = productHelpers.convertAttributes(product.attributes);
    const sizeScreenFilter = attrMap['def-pantalla'];
    if (!allSizeScreenResult.includes(sizeScreenFilter) && sizeScreenFilter) {
      allSizeScreenResult.push(sizeScreenFilter);
    }
  });
  // }

  return allSizeScreenResult;
};

export default productHelpers;
